export enum SupportedLanguages {
  default = 'default',
  es = 'es'
}

export type ServerResponse<T> = {
  message: string;
  data: T
}

export interface Extensions {
  pages?: any;
  questions?: any;
}

export enum typesPages {
  SKIPPABLE = 'skippable'
}

export interface PageExtension {
  type: typesPages;
  names: string[];
  buttonName: {
    es: string;
    default: string;
  }
  buttonNameLast: {
    es: string;
    default: string;
  }
  completeName: {
    es: string;
    default: string;
  }
  defaultCompleteName: {
    es: string;
    default: string;
  }
  customButtonNames: any;
}


export type LocalizedText = {
  [key in SupportedLanguages]: string;
};