import Handlebars from "handlebars";
import { LocalizedText } from "../utils/common-types";

export const replaceValues = (message: LocalizedText, context: any): LocalizedText | undefined => {
  const result = {
    es: '',
    default: ''
  };
  try {
    if (message.es && message.default) {
      const templateEs = Handlebars.compile(message.es);
      const templateDefault = Handlebars.compile(message.default);
      result.es = templateEs(context);
      result.default = templateDefault(context);
      return result;
    }
  } catch (error) {
    console.log(error);
  }
  return undefined;
};
