import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeader, MainLayout } from '../../components/main.layout';
import { LocalizedText, SupportedLanguages } from '../../utils/common-types';

interface ScreenState {
  title: LocalizedText;
  message: LocalizedText;
  preferredLanguage?: SupportedLanguages;
}

const defaultState: ScreenState = {
  title: {
    es: '¡Gracias!',
    default: 'Thank you!',
  },
  message: {
    es: '<p>Gracias por tu interés para darnos tu opinión.</p><p>Recibirás una invitación personalizada la próxima vez que interactúes con nosotros.</p>',
    default: '<p>Thank you for your interest.</p><p>You will receive an invitation the next time you interact with us.</p>',
  }
};

export const Message: FC<RouteComponentProps<any, any, ScreenState>> = ({
  location: { state: { title, message, preferredLanguage } = defaultState}
}) => {
  const [selectedLocale, setSelectedLocale] = useState(preferredLanguage || SupportedLanguages.es)

  return (
    <>
      <PageHeader>
        <select
            onChange={ev => setSelectedLocale(ev.target.value as SupportedLanguages)}
            className="lang-selector"
            defaultValue={selectedLocale}
          >
            <option value="es">Español</option>
            <option value="default">English</option>
        </select>
      </PageHeader>
      <MainLayout>
        <div className="message">
          <h2 style={{ color: 'var(--color-primary)', marginBottom: '32px' }}>{ title[selectedLocale] }</h2>
          <div className="message-text" dangerouslySetInnerHTML={{ __html: message[selectedLocale] }} />
        </div>
      </MainLayout>
    </>
  );
}