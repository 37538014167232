import React, { FC } from 'react';

export const PageHeader: FC = ({ children }) => (
  <header className="survey-header">
    <img src={process.env.REACT_APP_URL_BRAND_LOGO} height="48" alt="" />
    {children}
  </header>
);

export const MainLayout: FC = ({ children = undefined }) => (
  <main className="survey-main">
    <div className="container">
      {children}
    </div>
  </main>
);