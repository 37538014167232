import { useHistory } from "react-router-dom";

export const useSearchParams = <T>(): T => {
  const history = useHistory();
  const search = history.location.search;
  let params: Record<string, any> = {};
  if (!search) return params as T;
  const pairs = search.split(/[?&]/);
  pairs.forEach(p => {
    if (!p.length) return;
    const [key, value] = p.split('=');
    params[key] = decodeURI(value);
  });

  return params as T;
}