import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivacyPolicy } from './legal';
import { Unsubscribe } from './legal/Unsubscribe';
import { Survey, Message, SurveyIndex } from './survey';

function App() {
  return (
    <Switch>
      <Route exact path="/aviso-de-privacidad" component={PrivacyPolicy} />
      <Route exact path="/unsubscribe/:externalId/:token" component={Unsubscribe} />
      <Route exact path="/oops" component={Message} />
      <Route exact path="/thank-you" component={Message} />
      <Route exact path="/test-mode" component={SurveyIndex} />
      <Route exact path="/:id" component={Survey} />
      <Route exact path="/survey/:id" component={Survey} />
      <Route exact path="/test/:id" component={Survey} />
      <Route exact path="/" component={Message} />
    </Switch>
  );
}

export default App;