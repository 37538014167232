import { API_PREFIX, API_ROOT_DOMAIN, API_URL } from "../utils/constants";


export const resolveBaseUrl = () => {
  if (!window.location.host.includes('localhost') && window.location.host.endsWith(API_ROOT_DOMAIN)) {
    const SURVEY_ENV = window.location.host.split('.')[1];
    return `${window.location.protocol}//${API_PREFIX}.${SURVEY_ENV}.${API_ROOT_DOMAIN}`;
  }

  return API_URL;
}

export const executeFunction = (ctx: any, body: string) => {
  const fn = new Function('ctx', body);
  return fn(ctx);
};
