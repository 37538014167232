import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ContactService from '../../services/contact';
import { MainLayout, PageHeader } from '../../components/main.layout';
import { LocalizedText, SupportedLanguages } from '../../utils/common-types';
import { useSearchParams } from '../../utils/navigation';

interface SearchParams {
  l: string; // Language
  e: string; // Email
}

interface PathParams {
  externalId: string;
  token: string;
}

interface ScreenParams extends PathParams, SearchParams {}

interface UnsubscribeTranslation {
  title: string;
  message: string;
  button: string;
  buttonProcessing: string;
}

function getUnsubscribeTranslations (language: SupportedLanguages, params: ScreenParams): UnsubscribeTranslation {
  const t: Record<SupportedLanguages, UnsubscribeTranslation> = {
    [SupportedLanguages.default]: {
      title: `Unsubscribe ${params.e}`,
      message: `Stop receiving surveys for ${params.e}`,
      button: `Unsubscribe`,
      buttonProcessing: `Processing...`,
    },
    [SupportedLanguages.es]: {
      title: `Desuscribir ${params.e}`,
      message: `Dejar de recibir encuestas para el correo ${params.e}`,
      button: `Desuscribirse`,
      buttonProcessing: `Procesando...`,
    }
  }
  if (Object.values(SupportedLanguages).includes(language)) {
    return t[language];
  } else {
    return t[SupportedLanguages.es]
  }

}

export const Unsubscribe = () => {
  const match = useRouteMatch<PathParams>();
  const { params: { externalId, token } } = match;
  const { l, e } = useSearchParams<SearchParams>();
  const translations = getUnsubscribeTranslations(l as SupportedLanguages, { externalId, token, e, l });
  const [displayMessage, setDisplayMessage] = useState(translations.message);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const cancelSub = async () => {
    let resultMessage = '';
    const defaultError: LocalizedText = {
      default: `We couldn't process your request. Please try again later.`,
      es: 'No pudimos procesar tu solicitud. Por favor intenta de nuevo más tarde.'
    };
    setLoading(true);
    try {
      const responseMessage = await ContactService.unsubscribe(externalId, token);
      resultMessage = responseMessage[l as SupportedLanguages];
    } catch (e) {
      resultMessage = defaultError[l as SupportedLanguages];
    } finally {
      setDisplayMessage(resultMessage);
      setDone(true);
      setLoading(false);
    }
  }

  if (!externalId || !token) return null;
  
  return (
    <>
      <PageHeader />
      <MainLayout>
        <div className="message">
          <h2 style={{ color: 'var(--color-primary)', marginBottom: '32px' }}>{ translations.title }</h2>
          <p className="message-text">{ displayMessage }</p>
          {!done && (
            <button
              className="action-button"
              onClick={cancelSub}
              disabled={loading}
            >
              {loading ? translations.buttonProcessing : translations.button}
            </button>
          )}
        </div>
      </MainLayout>
    </>
  );
}
