import { LocalizedText, ServerResponse, SupportedLanguages, Extensions } from '../utils/common-types';
import { API_URL } from '../utils/constants';
//import { resolveBaseUrl } from './helpers';

interface AnyQuestion {
  type: string;
  name: string;
  title?: any;
  valueName?: string;
  isRequired?: boolean;
}

interface Page {
  name: string;
  elements: Array<AnyQuestion>
}

export interface SurveyDef {
  _id: string;
  pages: Array<Page>;
}

interface RemoteSurveyConfig {
  answer: {
    _id: string;
  };
  config: SurveyDef;
  enrichedRecord: {
    _id: string;
    [k: string]: any;
  };
  language: SupportedLanguages;
  privacyPolicy: LocalizedText;
  thankyouMessage: {
    title: LocalizedText;
    message: LocalizedText;
  };
  ruleCompleteMessage?: string;
  extensions?: Extensions;
}

export interface SurveyConfig {
  answerId?: string;
  surveyDef?: SurveyDef;
  data?: object;
  preferredLanguage?: SupportedLanguages;
  complete?: Record<string, LocalizedText>;
  privacyPolicy?: LocalizedText;
  ruleCompleteMessage?: string;
  extensions?: Extensions;
}

interface ServerError {
  errorCode: string;
  message: LocalizedText;
}

class ResponseService {

  /*constructor () {
    this.baseUrl = resolveBaseUrl();
  }*/

  baseUrl = API_URL;

  loadSurveyDef = async (token: string): Promise<SurveyConfig> => {
    const fetchOpts: RequestInit = {
      method: 'GET',
    };
    const URL = `${this.baseUrl}/survey/token/${token}`
    const fetchResponse: Response = await fetch(URL, fetchOpts);
    if (fetchResponse.status >= 400) {
      let errorMessage: LocalizedText = {
        es: 'Lo sentimos. Ocurrió un problema al cargar la encuesta.',
        default: 'Sorry! We had a problem loading your survey.'
      };
      const errorDetails: ServerError = await fetchResponse.json();
      if (errorDetails.message) {
        errorMessage = errorDetails.message;
      }
      // @ts-ignore
      throw new Error('ERROR_RETREIVING_SURVEY_DEF', { cause: errorMessage });
    }
    const response: ServerResponse<RemoteSurveyConfig> = await fetchResponse.json();
    const { answer, config, enrichedRecord, language, thankyouMessage, privacyPolicy, ruleCompleteMessage, extensions  } = response.data

    return {
      answerId: answer._id,
      surveyDef: config,
      data: enrichedRecord,
      preferredLanguage: language,
      complete: thankyouMessage,
      privacyPolicy,
      ruleCompleteMessage,
      extensions
    };
  };

  loadSurveyAnonymousDef = async (id: string, params?: any): Promise<SurveyConfig> => {
    const URL = `${this.baseUrl}/survey/anonymous/${id}`;
    const body = JSON.stringify(params)
    const fetchOpts: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    };
    const fetchResponse: Response = await fetch(URL, fetchOpts);
    if (fetchResponse.status >= 400) {
      let errorMessage: LocalizedText = {
        es: 'Lo sentimos. Ocurrió un problema al cargar la encuesta.',
        default: 'Sorry! We had a problem loading your survey.'
      };
      const errorDetails: ServerError = await fetchResponse.json();
      if (errorDetails.message) {
        errorMessage = errorDetails.message;
      }
      // @ts-ignore
      throw new Error('ERROR_RETREIVING_SURVEY_DEF', { cause: errorMessage });
    }
    const response: ServerResponse<RemoteSurveyConfig> = await fetchResponse.json();
    const { answer, config, enrichedRecord, language, thankyouMessage, privacyPolicy, ruleCompleteMessage, extensions } = response.data

    return {
      answerId: answer._id,
      surveyDef: config,
      data: enrichedRecord,
      preferredLanguage: language,
      complete: thankyouMessage,
      ruleCompleteMessage,
      extensions,
      privacyPolicy
    };
  };

  formatResponse = (response: any): any => {
    let multipleQuestions = [];
    let finalResponse: any = {};
    for (const k in response) {
      if (Array.isArray(response[k])) {
        multipleQuestions.push(k);
      } else if (typeof response[k] === 'object') {
        for (const name in response[k]) {
          finalResponse[name] = response[k][name];
        }
      } else {
        finalResponse[k] = response[k];
      }
    }
    multipleQuestions.forEach(k => {
      response[k].forEach((answerId: string) => {
        finalResponse[`${k}_${answerId}`] = true;
      });
    })
    return finalResponse;
  }

  saveResponse = async (responseId: string, response: any, rawResponse: any, language: SupportedLanguages): Promise<any> => {
    const formattedResponse = this.formatResponse(response);
    const body = JSON.stringify({
      data: formattedResponse,
      rawResponse,
      language
    });
    console.log('Saving response', body);
    const fetchOpts: RequestInit = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    };
    let URL = `${this.baseUrl}/answer/save/${responseId}`;
    const fetchResponse: Response = await fetch(URL, fetchOpts);
    const updateResponse = await fetchResponse.json();
    return updateResponse;
  };

}

export default new ResponseService();